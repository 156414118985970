@use 'src/src/app/scss/abstracts' as *;

:root {
  --slider-progress: 0%;
}

.slider {
  $block-name: &;

  position: relative;
  z-index: 0;

  //   &#{$block-name} {
  //   }

  &__link {
    display: block;
    border-radius: 8px;
    overflow: hidden;
    transform: translate3d(0, 0, 0) scale(1);
    transition: transform $transition-slow;

    @include mq(sm) {
      border-radius: 4px;
    }

    @include mq(lg) {
      border-radius: 8px;
    }

    &--has-link {
      &:hover {
        transform: translate3d(0, 0, 0) scale(0.98);

        #{$block-name} {
          &__pseudo-button {
            opacity: 1;
          }
        }
      }

      &:active {
        transform: translate3d(0, 0, 0) scale(0.96);
      }
    }
  }

  &__inner {
  }

  &__main {
    border-radius: 8px;
    overflow: hidden;

    @include mq(sm) {
      border-radius: 4px;
    }

    @include mq(lg) {
      border-radius: 8px;
    }
  }

  &__thumbnails {
    margin-top: 16px;
    margin-left: -8px;
    margin-right: -8px;
    overflow: hidden;

    @include mq($until: lg) {
      margin-top: 9px;
      margin-left: -4px;
      margin-right: -4px;
    }

    @include mq($until: md) {
      margin-top: 19px;
      margin-left: 0;
      margin-right: 0;
    }

    swiper-container {
      box-sizing: border-box;
      display: flex !important;
      flex-wrap: nowrap !important;
    }

    swiper-slide {
      box-sizing: border-box;
      display: flex;
      flex: 0 0 auto !important;
      flex-direction: column;
      flex-grow: 1;
      width: 25%;

      .video-and-image,
      .video,
      .image {
        display: none;

        @include mq(md) {
          display: block;
        }
      }

      @include mq($until: md) {
        width: 25%;
        height: 10px;
      }

      #{$block-name} {
        &__thumb-inner {
          position: relative;
          border: 4px solid transparent;

          @include mq($until: lg) {
            border-width: 2px;
          }

          @include mq($until: md) {
            background-color: $color-base-purple-900;
            height: 10px;
            border-width: 0;
          }

          &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: var(--slider-progress);
            background-color: $color-base-purple-500;
            opacity: 0;

            @include mq($until: md) {
              width: 100%;
            }
          }
        }
      }
    }

    .swiper-slide-thumb-active {
      #{$block-name} {
        &__thumb-inner {
          border-color: $color-base-purple-500;

          &::after {
            display: block;
            opacity: 0.6;
          }
        }
      }
    }
  }

  &__thumb {
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;

    @include mq($until: lg) {
      padding-left: 4px;
      padding-right: 4px;
    }

    @include mq($until: md) {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &__thumb-inner {
    border-radius: 8px;
    overflow: hidden;

    @include mq($until: lg) {
      border-radius: 4px;
    }

    @include mq($until: md) {
      border-radius: 6px;
    }
  }

  &__pseudo-button {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity $transition-fast;
  }
}
